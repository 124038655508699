import React, { useEffect, useRef, useState } from "react";
import s from "../assets/scss/home.module.scss";

import cx from "classnames";

import referenceSymantec from "../assets/img/referenceLogos/symantec.svg";
import referenceVeritas from "../assets/img/referenceLogos/veritas.svg";
import referenceDell from "../assets/img/referenceLogos/dell-technologies.svg";
import referenceIbm from "../assets/img/referenceLogos/ibm.svg";

import referenceVmware from "../assets/img/referenceLogos/vmware.svg";
import referenceCisco from "../assets/img/referenceLogos/cisco.svg";
import referenceLenovo from "../assets/img/referenceLogos/lenovo.svg";
import referenceFileOrbis from "../assets/img/referenceLogos/file-orbis.svg";
import referenceS3m from "../assets/img/referenceLogos/s3m-logo.png";
import referencePaloalto from "../assets/img/referenceLogos/paloalto-logo.png";
import referenceMirket from "../assets/img/referenceLogos/mirket-logo.png";

import referenceIvanti from "../assets/img/referenceLogos/ivanti.svg";
import referenceOctoxlabs from "../assets/img/referenceLogos/octoxlabs.svg";
import referenceGtb from "../assets/img/referenceLogos/gtb-technologies.png";

import referenceCrowdstrike from "../assets/img/referenceLogos/crowdstrike.svg";
import referenceProofpoint from "../assets/img/referenceLogos/proofpoint.svg";
import referenceAws from "../assets/img/referenceLogos/aws.svg";
import referenceManageEngine from "../assets/img/referenceLogos/manageengine.svg";

// import referenceAcronis from "../assets/img/referenceLogos/acronis.svg";
// import referenceAdobe from "../assets/img/referenceLogos/adobe.svg";
// import referenceManageEngine from "../assets/img/referenceLogos/manage-engine.svg";
// import referenceVeeam from "../assets/img/referenceLogos/veeam.svg";
// import referenceDellEmc from "../assets/img/referenceLogos/dellemc.svg";

import sphere from "../assets/img/sphere.svg";
import sphereText from "../assets/img/sphere-text.svg";
import ellipsis from "../assets/img/ellipsis.svg";
import { ReactComponent as ArrowSquare } from "../assets/icons/arrow-square.svg";
import veritas from "../assets/img/veritas-logo.png";

import AnimatedButton from "../components/AnimatedButton";
import Carousel from "../components/Carousel";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ContactForm from "../components/ContactForm";

import Slider from "../components/Slider";
import AchievementCard from "../components/AchievementCard";
import ReferencesMobile from "../components/ReferencesMobile";

import slider1 from "../assets/img/iyilestirme.jpg";
import slider2 from "../assets/img/siber.jpg";
import slider3 from "../assets/img/duyuru.jpg";
import slider4 from "../assets/img/bakim.jpg";

import a1 from "../assets/video/a1.mp4";
import a2 from "../assets/video/a2.mp4";
import a3 from "../assets/video/a3.mp4";
import a4 from "../assets/video/a4.mp4";

import axios from "axios";
import { Link } from "react-router-dom";
import { breakpointTypes } from "../utils/types";
import useWindowSize from "../hooks/useWindowSize";
import PinnedWrapper from "../hocs/PinnedWrapper";
import useCursorStore from "../stores/cursorStore";

export default function Home() {
  const homeRef = useRef(null);
  const windowSize = useWindowSize();

  const { circle } = useCursorStore();

  // FETCH  DATA
  useEffect(() => {
    axios
      .get("https://ateon.com.tr/jd-admin/services/comment.php")
      .then((res) => {
        const data = res.data;
        if (data.active) {
          setComments(data.items);
          setIsActive(data.active);
        }
        // console.log(res.data);
      })
      .catch((err) => {
        console.log("COMMENT_ERR", err);
      });

    axios
      .get("https://ateon.com.tr/jd-admin/services/slider.php")
      .then((res) => {
        const data = res.data;
        setSlides(data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log("SLIDER_ERR", err);
      });
  }, []);

  const cards = [
    {
      tag: "Servis Yenileme Oranı",
      icon: a1,
      numbers: [
        ["0", "0", ""],
        ["%", "9", "8", "*"],
      ],
      text: "Müşteri memnnuniyetine bağlı servis yenileme oranı.",
    },
    {
      tag: "Kapanmış Çağrı Sayısı",
      icon: a4,
      numbers: [
        ["0", "0", "0", "0", ""],
        ["1", "8", "2", "3", "*"],
      ],
      text: "Son 2 yılda sonuçlanan çağrı sayısı.",
    },
    {
      tag: "Ortalama Çağrı Süresi",
      icon: a3,
      numbers: [
        ["0", "0", ""],
        ["7", "3", "d", "k", "*"],
      ],
      text: "Çağrı başına ortalama harcanan süre.",
    },
    {
      tag: "SLA Performans Değeri",
      icon: a2,
      numbers: [
        ["0", "0", ".", "0", ""],
        ["%", "9", "9", ".", "8", "*"],
      ],
      text: "Son 2 yılın SLA performans değeri.",
    },
  ];

  const sampleComments = [
    {
      quote: "1 -Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      imgSrc:
        "https://images.unsplash.com/photo-1597223557154-721c1cecc4b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
      personName: "1 - Samantha Forbes",
      personTitle: "1 - Product Tactics Assistant, Stackovo.com",
    },
    {
      quote:
        "2 - Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit vel estatque consectetur temporibus, ratione aut corporis error, null a praesentium quia aliquam deserunt dignissimos exercitationem quidem ab? Nesciunt, ut nostrum!",
      imgSrc:
        "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=699&q=80",
      personName: "2 - Samantha Forbes",
      personTitle: "2 - Product Tactics Assistant, Stackovo.com",
    },
    {
      quote:
        "3 - Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit vel estatque consectetur temporibus, ratione aut corporis error, null a praesentium quia aliquam deserunt dignissimos exercitationem quidem ab? Nesciunt, ut nostrum!",
      imgSrc:
        "https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",
      personName: "3 - Samantha Forbes",
      personTitle: "3 - Product Tactics Assistant, Stackovo.com",
    },
    {
      quote:
        "4 - Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit vel estatque consectetur temporibus, ratione aut corporis error, null a praesentium quia aliquam deserunt dignissimos exercitationem quidem ab? Nesciunt, ut nostrum!",
      imgSrc:
        "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
      personName: "4 - Samantha Forbes",
      personTitle: "4 - Product Tactics Assistant, Stackovo.com",
    },
  ];

  const sampleSlider = [
    {
      summary: "Duyurular",
      subtitle: "Teknoloji devlerinin buluştuğu konferans:",
      title: "Duyurular",
      imgSrc: slider3,
    },
    {
      summary: "Siber Güvenlik Çözümleri",
      subtitle: "Dijital dönüşümün en önemli parçası:",
      title: "Siber Güvenlik Çözümleri",
      imgSrc: slider2,
    },
    {
      summary: "İyileştirme Hizmetleri",
      subtitle: "Teknoloji yarışında geride kalmayın:",
      title: "İyileştirme Hizmetleri",
      imgSrc: slider1,
    },
    {
      summary: "Bakım ve Onarım Hizmetleri",
      subtitle: "İşyerinizde süreklilik sağlayın:",
      title: "Bakım ve Onarım Hizmetleri",
      imgSrc: slider4,
    },
  ];

  useEffect(() => {
    const clearAnims = () => {
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill();
      });

      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window);
    };

    return () => {
      clearAnims();
    };
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [comments, setComments] = useState(sampleComments);
  const [slides, setSlides] = useState(sampleSlider);

  return (
    <main data-content className={s.homeWrapper} ref={homeRef}>
      {/* <VantaContainer></VantaContainer> */}
      <Slider sliderItems={slides}></Slider>
      <section data-digital className={s.digital}>
        <small className={s.small}>
          Ateon Bilgi Teknolojileri olarak <br /> neler yapıyoruz?
        </small>
        <div className={s.titleWrapper} data-title data-speed="0.3">
          <div className={s.textWithImage}>
            <h1 className={cx(s.title, s.left)}>Dijital</h1>
            <h1 className={cx(s.title, s.right)}>dönüşüm</h1>
            <img
              className={s.ellipsis}
              src={ellipsis}
              alt="Ellipsis"
              data-ellipsis
            />
          </div>

          <h1 className={s.titleHelonik}>
            yolculuğu boyunca işletmelerin yanında yer alıyoruz.
          </h1>
        </div>
        <Link to={"/hakkimizda"} className={s.btnWrapper}>
          <AnimatedButton text="Hakkımızda"></AnimatedButton>
        </Link>
      </section>
      <section data-content-item className={s.services}>
        <div className={s.left} data-title data-speed="0.2">
          <h1 className={s.title}>
            Hizmetlerimizi <br /> inceleyin.
          </h1>
          <ArrowSquare className={s.aSquareWrapper} />
        </div>
        <div className={s.right}>
          <img className={s.veritas} src={veritas} alt="Veritas Logo" />
          <p className={s.text}>
            Danışmanlık şirketlerinin çoğu sadece sorunların çözümüne
            odaklanırken, ATEON, firmanıza özel derinlemesine stratejileri ile
            olguyu geniş bir perspektifte değerlendirir.
          </p>
        </div>
      </section>

      <PinnedWrapper></PinnedWrapper>

      <section data-content-item className={s.references}>
        <div className={s.sphereWrapper} data-sphere-wrapper>
          <img data-sphere className={s.sphere} src={sphere} alt="Sphere" />
          <img
            className={s.sphereText}
            src={sphereText}
            alt="Text"
            data-sphere-text
          />
        </div>
        <h4 className={s.title} data-title data-speed="0.4">
          Çalıştığımız dev firmalar ile <br /> hizmetlerimize{" "}
          <span>değer katıyoruz.</span>{" "}
        </h4>
      </section>

      {windowSize.width > breakpointTypes.mobile ? (
        <section data-reference-logos className={s.referenceLogos}>
          <div className={s.row}>
            <a
              href="https://securitycloud.symantec.com/cc/landing"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceSymantec}
                alt="Company Logo"
              ></img>
            </a>
            <a
              href="https://www.veritas.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceVeritas}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.dell.com"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceDell}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.ibm.com"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceIbm}
                alt="Company Logo"
              />
            </a>
          </div>

          <div className={s.row}>
            <a
              href="https://www.vmware.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceVmware}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.cisco.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceCisco}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.lenovo.com"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceLenovo}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.fileorbis.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceFileOrbis}
                alt="Company Logo"
              />
            </a>
          </div>

          <div className={s.row}>
            <a
              href="https://mirketsecurity.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceMirket}
                alt="Company Logo"
                style={{ maxHeight: "100%" }}
              />
            </a>
            <a
              href="https://www.octoxlabs.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceOctoxlabs}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://gttb.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceGtb}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.paloaltonetworks.com/cortex"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referencePaloalto}
                alt="Company Logo"
              />
            </a>

          </div>
          <div className={s.row}>
          <a
              href="https://www.proofpoint.com/us"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceProofpoint}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://s3msecurity.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceS3m}
                alt="Company Logo"
                style={{ maxHeight: 200 }}
              />
            </a>
            <a
              href="https://aws.amazon.com/tr/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceAws}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.manageengine.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceManageEngine}
                alt="Company Logo"
              />
            </a>
          </div>
        </section>
      ) : (
        <ReferencesMobile />
      )}

      <section className={s.achievements}>
        {cards.map((card, i) => {
          return <AchievementCard item={card} key={i} />;
        })}
      </section>

      {isActive && (
        <section className={s.feedbacks}>
          <Carousel carouselItems={comments} />
        </section>
      )}

      <section className={s.question}>
        <h4 className={s.tag}>Sorularınız mı var?</h4>
        <h5 className={s.mail}>
          İletişim formumuzu doldurabilir ya da{" "}
          <a
            onMouseEnter={() => circle()}
            onMouseLeave={() => circle()}
            className={s.link}
            href="mailto:bilgi@ateon.com.tr"
          >
            bilgi@ateon.com.tr
          </a>{" "}
          adresine mail atabilirsiniz.
        </h5>
      </section>
      <section data-contact className={s.contact}>
        <div className={s.bg} data-bg></div>
        <ContactForm />
      </section>
    </main>
  );
}
