import React from "react";
import s from "../assets/scss/referencesMobile.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/scss/slickReset.scss";

import Slider from "react-slick";

import referenceSymantec from "../assets/img/referenceLogos/symantec.svg";
import referenceVeritas from "../assets/img/referenceLogos/veritas.svg";
import referenceDell from "../assets/img/referenceLogos/dell-technologies.svg";
import referenceIbm from "../assets/img/referenceLogos/ibm.svg";

import referenceVmware from "../assets/img/referenceLogos/vmware.svg";
import referenceCisco from "../assets/img/referenceLogos/cisco.svg";
import referenceLenovo from "../assets/img/referenceLogos/lenovo.svg";
import referenceFileOrbis from "../assets/img/referenceLogos/file-orbis.svg";
import referenceS3m from "../assets/img/referenceLogos/s3m-logo.png";
import referenceIvanti from "../assets/img/referenceLogos/ivanti.svg";
import referenceOctoxlabs from "../assets/img/referenceLogos/octoxlabs.svg";
import referenceGtb from "../assets/img/referenceLogos/gtb-technologies.png";
import referenceMirket from "../assets/img/referenceLogos/mirket-logo.png";

import referenceCrowdstrike from "../assets/img/referenceLogos/crowdstrike.svg";
import referenceProofpoint from "../assets/img/referenceLogos/proofpoint.svg";
import referenceAws from "../assets/img/referenceLogos/aws.svg";
import referenceManageEngine from "../assets/img/referenceLogos/manageengine.svg";
import referencePaloalto from "../assets/img/referenceLogos/paloalto-logo.png";
export default function ReferencesMobile() {
  const settings = {
    speed: 400,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
  };

  return (
    <section className={s.referencesMobile}>
      <Slider {...settings}>
        <div>
          <div className={s.row}>
            <a
              href="https://securitycloud.symantec.com/cc/landing"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceSymantec}
                alt="Company Logo"
              ></img>
            </a>
            <a
              href="https://www.veritas.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceVeritas}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.dell.com"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceDell}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.ibm.com"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceIbm}
                alt="Company Logo"
              />
            </a>
          </div>
        </div>

        <div>
          <div className={s.row}>
            <a
              href="https://www.vmware.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceVmware}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.cisco.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceCisco}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.lenovo.com"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceLenovo}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.fileorbis.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceFileOrbis}
                alt="Company Logo"
              />
            </a>
          </div>
        </div>

        <div>
          <div className={s.row}>
          <a
              href="https://mirketsecurity.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceMirket}
                alt="Company Logo"
                style={{ maxHeight: "100%" }}
              />
            </a>
            <a
              href="https://www.octoxlabs.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceOctoxlabs}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://gttb.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceGtb}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.paloaltonetworks.com/cortex"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referencePaloalto}
                style={{ maxWidth: "80%" }}
                alt="Company Logo"
              />
            </a>
          </div>
          </div>
          <div>
          <div className={s.row}>
          <a
              href="https://www.proofpoint.com/us"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceProofpoint}
                style={{ maxWidth: "80%" }}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://s3msecurity.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceS3m}
                alt="Company Logo"
                style={{ maxHeight: 200 }}
              />
            </a>
            <a
              href="https://aws.amazon.com/tr/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceAws}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.manageengine.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceManageEngine}
                alt="Company Logo"
              />
            </a>
          </div>
          </div>
      </Slider>
    </section>
  );
}
