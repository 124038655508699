import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import s from "../assets/scss/footerNav.module.scss";
import IconArrow from "./Icon/IconArrow";

import cx from "classnames";
import { useRef } from "react";
import { useStickyNavStore } from "../stores/stickyNavStore";
import useWindowSize from "../hooks/useWindowSize";

export default function FooterNav({
  title = "title",
  items = null,
  hideIndex = null,
}) {
  const filterItems = (key) => {
    return key !== "index";
  };

  // HIDE STICKY LOGIC ON TABLET AND MOBILE
  const footerNavRef = useRef(null);
  const { isOpen, toggle } = useStickyNavStore();
  const [hiddenSticky, setHiddenSticky] = useState(false);
  const windowSize = useWindowSize();
  const stickyRef = useRef(document.querySelector("[data-sticky-item]"));

  useEffect(() => {
    stickyRef.current = document.querySelector("[data-sticky-item]");

    const handleSticky = () => {
      if (
        window.pageYOffset >
        footerNavRef.current.offsetTop - windowSize.height
      ) {
        setHiddenSticky(true);
      } else {
        setHiddenSticky(false);
      }
    };

    document.addEventListener("scroll", handleSticky);

    return () => {
      document.removeEventListener("scroll", handleSticky);
    };
  }, []);

  useEffect(() => {
    if (hiddenSticky) {
      if (stickyRef.current) stickyRef.current.style.opacity = "0";
      if (isOpen) toggle();
    } else {
      if (stickyRef.current) stickyRef.current.style.opacity = "1";
    }
  }, [hiddenSticky]);

  return (
    <div className={s.footerNav} data-footer-nav ref={footerNavRef}>
      <h2 className={s.navTitle}>{title}</h2>
      <ol className={s.nav}>
        {items &&
          Object.entries(items)
            .filter(([key, value]) => filterItems(key))
            .map(([key, value], i) => {
              // console.log(value);
              return (
                <li className={s.navItemWrapper} key={i}>
                  <Link
                    to={
                      value.parentPath === "hizmetler"
                        ? `../../${value.path}`
                        : `../${value.path}`
                    }
                    className={cx(s.navItem, { [s.active]: key === hideIndex })}
                  >
                    <div className={s.text}>
                      {(() => {console.log('trfooternav')})()}
                      <span className={s.itemIndex}>0{i + 1}</span>
                      <p className={s.par}> {value.ui}</p>
                    </div>
                    <div className={s.arrow}>
                      <IconArrow fill="#ffffff"></IconArrow>
                    </div>
                  </Link>
                </li>
              );
            })}
      </ol>
    </div>
  );
}
